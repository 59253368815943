<script setup lang="ts">
import delve from 'dlv'
import { findPage } from '~/utils/findPage'

const { locale } = useI18n()

const selectedCruiselines = defineModel({
  default: [],
})

const props = defineProps({
  availableCruiselines: {
    type: Array,
  },
})

const { data: cruiselines } = await useCachedAsyncData('cruiselines-page', () =>
  findPage('cruiselines-page', locale.value),
)

const mappingTopCruiselines = [
  'MSC Croisières', 'Costa Croisières', 'CFC', 'Hurtigruten', 'Croisieurope', 'Ponant',
]

const matchedCruiselines = ref([])

let cruiselinesShowMoreClickCount = 0
const seenCruiselines = new Set()

const tender = delve(cruiselines.value, 'tender', [])

const cruiselinesContent = tender.filter((item) => {
  if (item.activated && !seenCruiselines.has(item.slug)) {
    seenCruiselines.add(item.slug)
    return true
  }
  return false
})

const showMore = ref(false)

cruiselinesContent.forEach((cruiseline) => {
  matchedCruiselines.value.push(cruiseline)
})

const seeMore = () => {
  cruiselinesShowMoreClickCount++
  showMore.value = true
}

function toggleselectedCruiselines(cruiseline) {
  if (selectedCruiselines.value.includes(cruiseline.name)) {
    selectedCruiselines.value = selectedCruiselines.value.filter(item => item !== cruiseline.name)
  }
  else {
    selectedCruiselines.value.push(cruiseline.name)
  }
}

const sortedCruiselines = sortArrayWithMapping(matchedCruiselines.value, 'name', mappingTopCruiselines)

watch(
  () => props.availableCruiselines,
  async () => {
    matchedCruiselines.value = cruiselinesContent.filter(c => Object.values(props.availableCruiselines).includes(c.name))
  },
)
</script>

<template>
  <div class="cruiseline-search-container">
    <span
      v-if="selectedCruiselines && selectedCruiselines.length > 0"
      class="counter-destination"
    >
      {{ selectedCruiselines.length }} {{ selectedCruiselines.length > 1 ? $t('search.company.dropdown.selected.plural') : $t('search.company.dropdown.selected.single') }}
    </span>
    <div class="companies-list">
      <template v-for="(cruiseline, index) in sortedCruiselines">
        <div
          v-if="index < 6 || showMore"
          class="cruiseline-item"
          :class="{ selected: selectedCruiselines.includes(cruiseline.name) }"
          @click="toggleselectedCruiselines(cruiseline)"
        >
          <NuxtImg
            format="webp"
            loading="lazy"
            width="150"
            :src="cruiseline.image.url"
            :alt="cruiseline.name"
          />
          <span class="cruiseline-count">
            ({{ getNumberFromKey(availableCruiselines, cruiseline.name) }})
          </span>
          <UiIconsCheck
            v-if="selectedCruiselines.includes(cruiseline.name)"
            class="check"
          />
        </div>
      </template>
    </div>
    <span
      v-if="cruiselinesShowMoreClickCount < 1 && matchedCruiselines.length >= 6"
      class="see-more-cta"
      @click="seeMore"
    >
      <UiIconsPlus /> {{ $t('search.company.dropdown.link') }}
    </span>
  </div>
</template>

<style lang="scss">
.cruiseline-search-container {
  display: flex;
  flex-direction: column;

  .counter-destination {
    display: block;
    margin-bottom: $space-sm;
  }

  .companies-list {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1em;

    @media (min-width: map-get($grid-breakpoints, md)) {
      grid-template-columns: repeat(6, 1fr);
    }

    .cruiseline-item {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--color-navy-150);
      position: relative;
      cursor: pointer;
      padding: 1em;
      min-height: 3rem;

      &.selected {
        border-color: var(--color-navy-450);

        .check {
          position: absolute;
          transform: translateY(-50%);
          color: var(--color-green);
          top: 50%;
          right: $space-xs;
        }
      }

      img {
        width: 100%;
        display: block;
        height: 60px;
        object-fit: contain;
      }

      .cruiseline-count {
        position: absolute;
        top: 4px;
        right: 4px;
      }
    }
  }

  .see-more-cta {
    display: inline-flex;
    align-items: center;
    margin: 0 auto;
    padding-top: $space-md;
    font-size: 1.2rem;
    gap: $space-xs;
    cursor: pointer;
    text-decoration: underline;
    text-underline-offset: 0.3rem;
  }
}
</style>
